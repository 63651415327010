import React from "react";

import Figure from "components/Figure";
import ThemeImage from "components/Image";

const Image = ({ image, imageModule, caption, ...props }) => {
  if (caption) {
    return (
      <Figure caption={caption}>
        <ThemeImage {...image} {...imageModule} {...props} />
      </Figure>
    );
  }

  return <ThemeImage {...image} {...imageModule} {...props} />;
};

export default Image;
